import React from "react";
import "./Certification.css";
// import CertificationImage from "../../assets/Certification.png";
// import MobileCertificationImage from "../../assets/MobileCertificationImage.png";
import ISOLogo from  "../../assets/iso.png";
import Trophy from  "../../assets/iso2.png";
import ICTAward from  "../../assets/iso3.png";
import CIMALogo from  "../../assets/iso4.png";
import ACCALogo from "../../assets/iso5.png";
import AwardsBg from "../../assets/Awards.png"

const Certification: React.FC = () => {
  return (
    <section className="certification-container"
      style = {{backgroundImage: `url(${AwardsBg})`}}
    >
  

      <div className="certification-content">
        {/* Title Section */}
        <div className="certification-title">
          <span className="certification-badge">CERTIFIED</span>
          <h2 className="certification-heading">Industry Appreciations</h2>
          <p className="certification-description">
            Tellida has earned widespread industry recognition for delivering exceptional 
            outsourcing solutions. Our commitment to quality, innovation, and customer 
            satisfaction has garnered accolades, reinforcing our reputation as a trusted 
            partner across various sectors globally.
          </p>
        </div>

        {/* Logos Section */}
        <div className="certification-logos">
          <div className="certification-row">
            <img src={ISOLogo} alt="ISO Certified" className="certification-logo" />
            <img src={Trophy} alt="Industry Award" className="certification-logo" />
            <img src={ICTAward} alt="ICT Award" className="certification-logo" />
          </div>
          <div className="certification-row2">
            <img src={CIMALogo} alt="CIMA Certified" className="certification-logo" />
            <img src={ACCALogo} alt="ACCA Approved Employer" className="certification-logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certification;
