import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import CaseStudyDetail from "./pages/CaseStudyDetail";
import LegalProcessService from "./pages/services/legal-process/LegalProcessService";
import RemoteProcessService from "./pages/services/remote-section/RemoteProcessService";
import CallService from "./pages/services/call-section/CallSection";
import FinanceService from "./pages/services/finance-section/FinanceService";
import Loader from "./Loader"; 
import "./App.css";
// import ScrollToTop from "./ScrollToTop";
import Services from "./pages/Services";
import Privacy from "./pages/PrivacyPolicy";
import Cookies from "./pages/sections/Cookies";
import CookiesSettings from "./pages/CookiesSettings";
import TermsConditions from "./pages/TermsConditions";
import Blogs from "./pages/Blogs";
import BlogPostPage from "./pages/BlogPost";
import BlogPost from "./pages/BlogPost";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  

  useEffect(() => {
    if (location.state?.scrollToContact) {
      setTimeout(() => {
        const contactSection = document.getElementById("global-contacts-section");
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); 
    }
  }, [location]);

  useEffect(() => {
    
    if (location.state?.scrollToCaseStudy) {
      const CaseStudySection = document.getElementById("legal-case-studies-id");
      if (CaseStudySection) {
        setTimeout(() => {
          CaseStudySection.scrollIntoView({ behavior: "smooth" });
        }, 500); 
      }
    }
  }, [location]);

  return (
    <>
    
      {/* {loading && <Loader />}  */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services/legal-process" element={<LegalProcessService />} />
        <Route path="/services/tech-support" element={<RemoteProcessService />} />
        <Route path="/services/call-centre" element={<CallService />} />
        <Route path="/services/finance" element={<FinanceService />} /> 
        <Route path="/careers" element={<Careers />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blog/:postId" element={<BlogPost />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/case-study-detail" element={<CaseStudyDetail />} />
        <Route path="/policy" element={<Privacy />} />
        <Route path="/cookies" element={<CookiesSettings />} />
        <Route path="/terms" element={<TermsConditions />} />
      </Routes>
    </>
  );
}

export default App;
